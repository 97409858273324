import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { UserAuth } from "../pages/auth/Auth";
import Loader from "../pages/landing/loder/Loader";

const LandingMain = React.lazy(() => import('../LandingMain'));
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgotPassword = React.lazy(() => import('../pages/auth/ForgotPassword'));
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const SalesDashboard = React.lazy(() => import("../pages/SalesDashboard"));

//settings
const OfficeTiming = React.lazy(() => import("../pages/settings/OfficeTiming"));
const Department = React.lazy(() => import("../pages/settings/Department"));
const NotificationSetting = React.lazy(() => import("../pages/settings/NotificationSetting"));
const WhatsappSetting = React.lazy(() => import("../pages/settings/WhatsappSetting"));
const CompanyInfo = React.lazy(() => import("../pages/settings/CompanyInfo"));
//product
const MyproductList = React.lazy(() => import("../pages/product/AllProducts"));
const MycategoryList = React.lazy(() => import("../pages/product/AllCategories"));
const AddNewcategory = React.lazy(() => import("../pages/product/AddNewcategory"));
const EditCategory = React.lazy(() => import("../pages/product/EditCategory"));
const AddNewProduct = React.lazy(() => import("../pages/product/AddNewProduct"));
const EditProduct = React.lazy(() => import("../pages/product/EditProduct"));
//purchase
const MypurchaseList = React.lazy(() => import("../pages/rfq/MypurchaseList"));
const MypurchaseListRejected = React.lazy(() => import("../pages/rfq/MypurchaseListRejected"));
const MyNewpurchase = React.lazy(() => import("../pages/rfq/MyNewpurchase"));
const EditMyPurchase = React.lazy(() => import("../pages/rfq/EditMyPurchase"));
const MypurchaseOrderList = React.lazy(() => import("../pages/rfq/MypurchaseOrderList"));
const MypurchaseOrderListDone = React.lazy(() => import("../pages/rfq/MypurchaseOrderListDone"));
const MypurchaseOrderListafterrecv = React.lazy(() => import("../pages/rfq/MypurchaseOrderListafterrecv"));

const PurchaseOrderBill = React.lazy(() => import("../pages/rfq/PurchaseOrderBill"));
const PurchaseOrderRecv = React.lazy(() => import("../pages/rfq/PurchaseOrderRecv"));
const OrderConfirm = React.lazy(() => import("../pages/rfq/PurchaseOrderConfirm"));
const ConfirmedBill = React.lazy(() => import("../pages/rfq/ConfirmedBill"));

//user
const UsersList = React.lazy(() => import("../pages/user/UsersList"));
const MyNewpurchaseOrder = React.lazy(() => import("../pages/rfq/MyNewpurchaseOrder"));
const MyNewpurchaseOrderAdvance = React.lazy(() => import("../pages/rfq/MyNewpurchaseOrderAdvance"));
const EditProductMyPurchaseOrder = React.lazy(() => import("../pages/rfq/EditProductMyPurchaseOrder"));
const PendingApproval = React.lazy(() => import("../pages/managment/PendingApproval"));
const FinalApproval = React.lazy(() => import("../pages/managment/FinalApproval"));

//vendor
const MyVendorList = React.lazy(() => import("../pages/vendor/AllVendors"));
const AddNewVendor = React.lazy(() => import("../pages/vendor/AddNewVendor"));
const EditVendor = React.lazy(() => import("../pages/vendor/EditVendor"));
const FollowupOrder = React.lazy(() => import("../pages/follow/FollowupOrder"));
const RecvUpdate = React.lazy(() => import("../pages/follow/RecvUpdate"));

//sales module customers
const MyCustomersList = React.lazy(() => import("../pages/customer/AllCustomers"));
const AddNewCustomer = React.lazy(() => import("../pages/customer/AddNewCustomer"));
const EditCustomer = React.lazy(() => import("../pages/customer/EditCustomer"));

const MySalesList = React.lazy(() => import("../pages/sales/quotation/MysalesList"));
const MysalesListRejected = React.lazy(() => import("../pages/sales/quotation/MysalesListRejected"));
const MyNewsale = React.lazy(() => import("../pages/sales/quotation/MyNewsale"));
const EditMySales = React.lazy(() => import("../pages/sales/quotation/EditMySales"));
const RevisedMySales = React.lazy(() => import("../pages/sales/quotation/RevisedMySales"));

const MysalesOrderList = React.lazy(() => import("../pages/sales/quotation/MysalesOrderList"));
const MysalesOrderListDone = React.lazy(() => import("../pages/sales/quotation/MysalesOrderListDone"));
const PendingApprovalSales = React.lazy(() => import("../pages/sales/managment/PendingApproval"));
const Followupsales = React.lazy(() => import("../pages/sales/follow/FollowupOrder"));
function PrivateRoutes() {
  const { isLoggedIn } = UserAuth();
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<LandingMain />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/forget-password" element={isLoggedIn ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
        <Route path="/register" element={isLoggedIn ? <Navigate to="/dashboard" /> : <Register />} />
        <Route element={<ProtectedRoute isLogin={isLoggedIn} />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sales-dashboard" element={<SalesDashboard />} />
          {/* product */}
          <Route path="/products" element={<MyproductList />} />
          <Route path="/category" element={<MycategoryList />} />
          <Route path="/add-new-category" element={<AddNewcategory />} />
          <Route path="/edit-category/:id" element={<EditCategory />} />
          <Route path="/add-new-product" element={<AddNewProduct />} />
          <Route path="/edit-product/:id" element={<EditProduct />} />
          {/* purchase */}
          <Route path="/purchase" element={<MypurchaseList />} />
          <Route path="/rejected-purchase" element={<MypurchaseListRejected />} />
          <Route path="/purchase/new" element={<MyNewpurchase />} />
          <Route path="/purchase/:id" element={<EditMyPurchase />} />
          <Route path="/purchase-orders" element={<MypurchaseOrderList />} />
          <Route path="/purchase-orders-done" element={<MypurchaseOrderListDone />} />
         
          <Route path="/purchase-orders/new" element={<MyNewpurchaseOrder />} />
          <Route path="/purchase-orders/advance/:id" element={<MyNewpurchaseOrderAdvance />} />
          <Route path="/purchase-orders/:id" element={<EditProductMyPurchaseOrder />} />
          <Route path="/pending-approval" element={<PendingApproval />} />
          <Route path="/final-approval" element={<FinalApproval />} />
        
          <Route path="/purchase-orders/createbill/:id" element={<PurchaseOrderBill />} />
          <Route path="/purchase-orders/recvorder/:id" element={<PurchaseOrderRecv />} />
          <Route path="/purchase-orders-recved" element={<MypurchaseOrderListafterrecv />} />
          <Route path="/bill/confirm_order/:id" element={<OrderConfirm />} />
          <Route path="/confirmedbill/:id" element={<ConfirmedBill />} />
          <Route path="/recv_update" element={<RecvUpdate />} />
          <Route path="/followup" element={<FollowupOrder />} />
          {/* Settings */}
          <Route path="/office-timing" element={<OfficeTiming />} />
          <Route path="/department" element={<Department />} />
          <Route path="/notification-setting" element={<NotificationSetting />} />
          <Route path="/whatsapp-setting" element={<WhatsappSetting />} />
          <Route path="/company-info" element={<CompanyInfo />} />
          {/* user list */}
          <Route path="/users" element={<UsersList />} />
          {/* vendor */}
          <Route path="/vendors" element={<MyVendorList />} />
          <Route path="/add-new-vendor" element={<AddNewVendor />} />
          <Route path="/edit-vendor/:id" element={<EditVendor />} />

          {/* Sales Module Routes
          Customer */}
          <Route path="/customers" element={<MyCustomersList />} />
          <Route path="/add-new-customer" element={<AddNewCustomer />} />
          <Route path="/edit-customer/:id" element={<EditCustomer />} />
          <Route path="/sales/followup" element={<Followupsales />} />
          <Route path="/sales" element={<MySalesList />} />
          <Route path="/rejected-sales" element={<MysalesListRejected />} />
          <Route path="/sales/new" element={<MyNewsale />} />
          <Route path="/sales/:id" element={<EditMySales />} />
          <Route path="/sales/revised/:id" element={<RevisedMySales />} />
          <Route path="/sales-orders" element={<MysalesOrderList />} />
          <Route path="/sales-orders-done" element={<MysalesOrderListDone />} />
          
          <Route path="/sales/pending-approval" element={<PendingApprovalSales />} />
        

        </Route>
      </Routes>
    </Suspense>
  );
}

export default PrivateRoutes;
